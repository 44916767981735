import deFlag from '../images/languages/de.svg';
import gbFlag from '../images/languages/gb.svg';
import nlFlag from '../images/languages/nl.svg';
import deLanguage from '../translation/de.json';
import enLanguage from '../translation/en.json';
import nlLanguage from '../translation/nl.json';

export const localStorageLanguageKey = 'timer-app-language';

export enum Locale {
    nl = 'nl',
    en = 'en',
    de = 'de',
}

export interface Language {
    code: Locale;
    label: string;
    image?: string;
}

export const languages: Language[] = [
    { code: Locale.nl, label: 'Nederlands', image: nlFlag },
    { code: Locale.de, label: 'Deutsch', image: deFlag },
    { code: Locale.en, label: 'English', image: gbFlag },
];

const locales: Record<Locale, any> = {
    [Locale.nl]: nlLanguage,
    [Locale.en]: enLanguage,
    [Locale.de]: deLanguage,
};

export const getLocale = (): Locale => {
    if (typeof window === 'undefined') {
        return Locale.nl;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const locationLanguage = urlParams.get('language');
    const localStorageLanguage = localStorage.getItem(localStorageLanguageKey);
    const language = locationLanguage || localStorageLanguage;

    if (language === Locale.de) {
        return Locale.de;
    }

    if (language === Locale.en) {
        return Locale.en;
    }

    return Locale.nl;
};

const trans = (path: string, params?: Record<string, string | number | undefined>): string => {
    const selectors = path.split('.');
    const paramKeys = Object.keys(params || {});

    const locale = getLocale();
    document.documentElement.lang = locale;

    let translation = locales[locale];

    for (let i = 0; i < selectors.length; i += 1) {
        const nextStep = translation[selectors[i]];

        if (!nextStep) {
            console.error(`No translation found for: ${path}`);
            return path;
        }

        translation = nextStep;
    }

    if (typeof translation !== 'string') {
        console.error(`No translation found for: ${path}`);
        return path;
    }

    if (!params) {
        return translation;
    }

    for (let i = 0; i < paramKeys.length; i += 1) {
        const regex = new RegExp(`:${paramKeys[i]}:`, 'g');

        translation = translation.replace(regex, params[paramKeys[i]]);
    }

    return translation;
};

export const getLanguage = (): Language => {
    const locale = getLocale();

    return languages.find(language => language.code === locale) || languages[0];
};

export const setLanguage = (locale: Locale) => {
    localStorage.setItem(localStorageLanguageKey, locale);
};

export default trans;
