import { FC, ReactElement } from 'react';

import { Button } from '../../components';
import {
    getLanguage,
    languages,
    Locale,
    setLanguage,
} from '../../helpers/trans';

import './LanguageSelector.scss';

interface LanguageSelectorProps {
    className?: string;
}

export const LanguageSelector: FC<LanguageSelectorProps> = ({
    className = '',
}): ReactElement => {
    const currentLanguage = getLanguage();

    const handleLanguageChange = (newLanguage: Locale): void => {
        setLanguage(newLanguage);
        window.location.reload();
    };

    return (
        <div className={`language-selector ${className}`}>
            {languages.map(({ code, image, label }) => (
                <Button
                    key={code}
                    text={label}
                    onClick={(): void => handleLanguageChange(code)}
                    className={
                        `language-selector__button ${
                            code === currentLanguage.code
                                ? 'language-selector__button--active'
                                : ''
                        }`
                    }
                >
                    <img
                        src={image}
                        alt={code}
                        height={24}
                        className="language-selector__button-icon"
                    />
                </Button>
            ))}
        </div>
    );
};
