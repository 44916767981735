import { FC, ReactElement } from 'react';

import { useLocalStorage } from 'usehooks-ts';

import { InactiveScreen, Page } from '../../containers';
import { localStorageApiTokenKey } from '../../helpers/authorizedFetch';
import { logout } from '../../redux/user/userReducer';
import { useTypedDispatch } from '../../store';

const ConnectedInactiveScreen: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();
    const [, storeApiToken] = useLocalStorage<string | undefined>(localStorageApiTokenKey, undefined);

    const handleLogOutButtonClick = (): void => {
        storeApiToken(undefined);
        dispatch(logout());
    };

    return (
        <Page showLanguageSelector>
            <InactiveScreen onLogOutButtonClick={handleLogOutButtonClick} />
        </Page>
    );
};

export default ConnectedInactiveScreen;
