import { FC, PropsWithChildren, ReactElement } from 'react';

import packageJson from '../../../package.json';
import { ValkLogo } from '../../components';
import { LanguageSelector } from '../../compositions';
import { ConnectedCacheBuster } from '../../connectors';

import './Page.scss';

interface PageProps {
    isOffline?: boolean;
    showLanguageSelector?: boolean;
    className?: string;
}

const Page: FC<PropsWithChildren<PageProps>> = ({
    isOffline,
    showLanguageSelector = false,
    children,
    className = '',
}): ReactElement => (
    <div className={`page ${className}`}>
        <ValkLogo className="page__valk-logo" />

        {children}

        <div className="page__version-number">
            {packageJson.version}
            {isOffline && '*'}
        </div>

        {showLanguageSelector && (
            <LanguageSelector className="page__language-selector" />
        )}

        <ConnectedCacheBuster />
    </div>
);

export default Page;
